body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  /* background: rgba(1,57,205,0.9)!important; */
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 4vw;
  line-height: 4vw;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header .headerWrapper {
  position: relative;
  width: 100vw;
  height: 7.3vw;
  padding: 0 18vw 0;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 20vw;
}
header .headerWrapper .logo {
  width: 13.3vw;
}
header .headerWrapper .headerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8vw;
}
header .headerWrapper .headerBtn .button {
  position: relative;
  cursor: pointer;
  color: #333333;
  width: 6vw;
  margin: 0 0.8vw;
  text-align: center;
  font-size: 1.3vw;
  line-height: 2vw;
  height: 2vw;
  border-width: 0;
  font-family: PingFang SC;
  background-color: transparent;
  box-shadow: 0 0 0;
  font-weight: 600;
}
header .headerWrapper .headerBtn .button:hover {
  border-bottom: 1px solid #1E7C51;
  color: #1E7C51;
}
header .headerWrapper .headerBtn .active-btn {
  color: #1E7C51;
  border-width: 0;
  font-family: PingFang SC;
  border-bottom: 1px solid #1E7C51;
  box-shadow: 0 0 0;
}
header .headerWrapper .headerBtn .active-btn:hover {
  color: #333;
}
header .btn-white {
  position: relative;
  background: #fedb67;
  padding: 0 18vw 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.logoBanner {
  background: #5b77ee url(../../static/media/logoBanner.66613179.png) no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  overflow: hidden;
  height: 100vh;
}
.headerWrapper {
  height: 10vh !important;
}
.banner {
  height: 85vh !important;
}
.banner .bgWrapper {
  height: 100% !important;
  padding-top: 7vh !important;
  margin: 0 !important;
}
.banner .bgWrapper .content .title {
  margin-top: 3vh !important;
  font-size: 3vh!important;
  line-height: 3vh!important;
  height: auto!important;
  text-transform: uppercase;
}
.banner .bgWrapper .content .subtitle,
.banner .bgWrapper .content .desc {
  margin-top: 2.6vh !important;
  font-size: 2vh!important;
  line-height: 2vh!important;
  height: auto!important;
}
.banner .bgWrapper .imgWrapper img {
  height: 35vh !important;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.block {
  position: relative;
}
.banner {
  position: relative;
  margin: auto;
  width: 100vw;
  overflow: hidden;
  background-size: 100vw auto;
}
.banner .bgWrapper {
  position: relative;
  margin: 0 auto;
  margin-top: 12.5vw;
  margin-left: 19vw;
  margin-right: 16vw;
  text-align: left;
  display: block;
}
.banner .bgWrapper .content {
  position: relative;
  z-index: 1;
  text-align: left;
  padding-left: 18.8vw;
}
.banner .bgWrapper .content .title {
  width: 39.3vw;
}
.banner .bgWrapper .content .subtitle {
  margin-top: 4vw;
  font-size: 1.7vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.7vw;
  color: #fff;
  height: 3.4vw;
}
.banner .bgWrapper .content .desc {
  margin-top: 1vw;
  font-size: 1.7vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.7vw;
  color: #fff;
  height: 3.4vw;
}
.banner .bgWrapper .content .banner-btn {
  margin-top: 2.2vw;
  margin-bottom: 2.6vw;
  opacity: 1;
  cursor: pointer;
}
.banner .bgWrapper .content .banner-btn a {
  color: #fff;
  text-decoration: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner .bgWrapper .content .banner-btn .banner-img {
  margin-right: 0.5vw;
  width: 1.2vw;
  height: 0.9vw;
}
.banner .bgWrapper .imgWrapper {
  margin: 0 auto;
  text-align: center;
}
.banner .bgWrapper .imgWrapper img {
  height: 26.2vw;
}
.banner-ims {
  position: absolute;
  left: -3px;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
}
.banner-andriod {
  width: 17px;
  height: 20px;
  margin: -5px 10px 0 5px;
}
.banner-newIcon {
  width: 47px;
  height: 47px;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 999;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-download {
  width: 275px;
  position: relative;
  height: 110px;
  color: #fff;
  display: flex;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download div {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 4;
  color: #fff;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.block1 {
  overflow: initial;
  width: 100vw;
  height: 46.6vw;
  padding-left: 18.8vw;
  padding-right: 18.8vw;
  position: relative;
  overflow: hidden;
  background-image: url(../../static/media/bg_feature.c94c5115.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 5.2vw;
  padding-top: 8.3vw;
}
.block1 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block1 .topTip h2 {
  font-size: 2.2vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2.2vw;
  color: #FEDB67;
}
.block1 .topTip div {
  line-height: 1.8vw;
  width: 40.9vw;
  font-family: PingFang SC;
  font-size: 1.1vw;
  font-weight: 600;
  color: #FEDB67;
  margin: 1.6vw auto 0;
}
.block1 .block-main-content {
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 0;
}
.block1 .block-main-content .fsTitle {
  padding: 0;
  margin: 0;
  height: 67px;
  font-size: 48px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 67px;
  color: #3b3291;
  opacity: 1;
}
.block1 .block-main-content .fsDesc {
  padding: 0;
  margin: 14px 0 88px 0;
  width: 560px;
  font-size: 29px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 35px;
  color: #5e57a7;
  opacity: 1;
}
.block1 .one-row-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}
.block1 .page-main-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block1 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.6vw;
}
.block1 .item-feature .cardInfo {
  width: 14.5vw;
  height: 20vw;
  background-color: #fff;
  border-radius: 1.6vw;
  color: #333333;
  text-align: center;
  padding: 0 1.4vw;
  padding-top: 2.3vw;
}
.block1 .item-feature .cardInfo .cardTitle {
  padding-top: 2.3vw;
  font-size: 1.2vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 1.6vw;
  opacity: 1;
}
.block1 .item-feature .cardInfo .cardDetail {
  margin-top: 0.5vw;
  font-size: 0.8vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.15vw;
  opacity: 1;
}
.block1 .item0 .cardIcon {
  height: 5vw;
}
.block1 .item1 .cardIcon {
  height: 5vw;
}
.block1 .item2 .cardIcon {
  height: 5vw;
}
.block1 .item3 .cardIcon {
  height: 5vw;
}
.block1 .one-row-block :nth-child(odd) div img {
  width: 150px;
  height: 150px;
  left: calc(50% - 75px);
}
.block1 .one-row-block :nth-child(even) div img {
  width: 170px;
  height: 130px;
  left: calc(50% - 85px);
}
.block1 .card {
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  color: #868e96;
  display: inline-block;
  max-width: 360px;
  height: 272px;
  padding: 32px;
  box-shadow: 0 2px 2px rgba(84, 48, 132, 0.06);
  margin: 0 auto;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  text-align: center;
  border-radius: 4px;
}
.block1 .card .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.block1 .card h3 {
  font-size: 30px;
}
.block1 .card img {
  height: 50px;
  margin: 20px 0;
}
.block1 .card:hover {
  text-decoration: none;
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(84, 48, 132, 0.06);
}
.block3 {
  margin-top: 0;
  overflow: initial;
  height: 53vw;
  width: 100vw;
  padding: 0 22vw;
  padding-bottom: 5vw;
  position: relative;
  overflow: hidden;
  background-image: url(../../static/media/bg_footer.a7a8225f.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.block3 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: left;
  padding-left: 1vw;
}
.block3 .topTip h2 {
  font-family: PingFang SC;
  font-size: 3vw;
  font-weight: 600;
  color: #1E7C51;
  text-align: center;
  margin-bottom: 0;
}
.block3 .topTip div {
  width: 33vw;
  margin: 0 auto;
  font-size: 1.1vw;
  font-family: PingFang SC;
  line-height: 1.2vw;
  font-weight: 600;
  color: #1E7C51;
  text-align: center;
}
.block3 .page {
  padding: 0 2.3vw;
}
.block3 .one-card {
  background: #ffffff;
  opacity: 1;
  border-radius: 1.8vw;
  display: flex;
  align-items: center;
  margin-top: 1.6vw;
  padding: 1.6vw 1.4vw;
}
.block3 .one-card img {
  width: 8.3vw;
  height: 8.3vw;
}
.block3 .one-card .common-card {
  margin-left: 2vw;
  padding-top: 1vw;
}
.block3 .one-card .common-card .title {
  font-size: 1.1vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.1vw;
  margin-bottom: 0;
}
.block3 .one-card .common-card .detail {
  font-size: 1vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.1vw;
  color: #333333;
  margin-top: 1vw;
}
.block3 .one-card .common-card .detail p {
  margin-bottom: 0.5vw;
}
.block3 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block3 .item-feature .cardInfo {
  width: 20vw;
}
.block3 .item-feature .cardInfo .cardTitle {
  font-size: 2vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 3vw;
  opacity: 1;
  color: #2972FB;
}
.block3 .item-feature .cardInfo .cardDetail {
  margin-top: 1vh;
  font-size: 1.5vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2vw;
  opacity: 1;
}
.block3 .item-feature .card0 {
  text-align: left;
}
.block3 .item-feature .card1 {
  text-align: right;
}
.block3 .item-feature .card1 .cardTitle {
  text-align: right;
}
.block3 .item0 .cardIcon {
  width: 3.3vw;
  margin-right: 7vw;
}
.block3 .item1 {
  margin-top: 7vw;
}
.block3 .item1 .cardIcon {
  width: 20vw;
}
.block3 .item2 {
  margin-top: 7vw;
}
.block3 .item2 .cardIcon {
  margin-right: 7vw;
  width: 15vw;
}
.block3 .item3 {
  margin-top: 14vw;
}
.block3 .item3 .cardIcon {
  width: 18vw;
}
.block4 {
  min-height: 715px;
  padding: 130px 0;
  background: #F8FAFE;
}
.block4 .slick-slide {
  height: 455px;
}
.block4 .slick-slide .user {
  width: 98px;
  height: 98px;
  border-radius: 100%;
  margin: auto;
}
.block4 .slick-slide .comment {
  line-height: 48px;
  font-size: 20px;
  width: 100%;
  max-width: 680px;
  margin: 41px auto 47px;
  position: relative;
  color: #545454;
  font-weight: 300;
}
.block4 .slick-slide .comment > img {
  position: absolute;
  left: -40px;
  top: -15px;
}
.block4 .slick-slide > h4 {
  font-size: 30px;
  line-height: 38px;
  font-weight: normal;
}
.block4 .slick-slide > p {
  margin-top: 16px;
  font-size: 14px;
  color: #888888;
  font-weight: 300;
}
.block4 .slick-dots li {
  margin: 0 8px;
}
.block4 .slick-dots li button {
  transition: opacity 0.3s;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
}
.block4 .slick-dots li button:hover::before,
.block4 .slick-dots li button:focus::before {
  opacity: 1;
  width: 20px;
  height: 20px;
}
.block4 .slick-dots li button::before {
  transition: color 0.3s;
}
.block4 .slick-dots li.slick-active button {
  background: #064852;
}
.block4 .slick-dots li.slick-active button::before {
  opacity: 1;
  background: #064852;
}
.block5 {
  min-height: 590px;
}
.block5 h2 {
  margin: 120px auto 25px;
}
.block5 > span {
  margin-bottom: 72px;
}
.block5 .ant-row > div {
  margin-bottom: 20px;
}
.andriod {
  width: 17px;
  height: 18px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer {
  overflow: hidden;
}
.footer .bar {
  background: #ACBAF6;
  opacity: 1;
  color: #5B77EE;
  text-align: center;
  overflow: hidden;
  padding-bottom: 2vw;
  padding-top: 0.6vw;
}
.footer .bar .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
}
.footer .bar .one-center .title {
  padding: 0;
  margin: 0;
  font-size: 0.8vw;
  line-height: 1vw;
  margin-bottom: 1.6vw;
}
.footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.6vw;
  font-size: 1vw;
}
.footer .bar .one-center .infos .contImg {
  width: 1.5vw;
  height: 1vw;
  margin-right: 0.5vw;
  margin-top: 0.2vw;
}
.footer .bar .one-center-end {
  text-align: center;
}
.footer .bar .one-center-end .infos {
  margin-bottom: 1vw;
}
.footer .bar .one-center-end .infos a {
  color: #5B77EE;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer .bar .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .bottom-bar {
  overflow: hidden;
  height: 4.41vh;
  line-height: 4.41vh;
  text-align: center;
  background: #f8f8f8;
  color: #24368C;
  font-size: 0.7vw;
  font-family: PingFang SC;
  font-weight: 400;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.logoBanner {
  background: #5b77ee url(../../static/media/logoBanner.66613179.png) no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  overflow: hidden;
  height: 100vh;
}
.headerWrapper {
  height: 10vh !important;
}
.banner {
  height: 85vh !important;
}
.banner .bgWrapper {
  height: 100% !important;
  padding-top: 7vh !important;
  margin: 0 !important;
}
.banner .bgWrapper .content .title {
  margin-top: 3vh !important;
  font-size: 3vh!important;
  line-height: 3vh!important;
  height: auto!important;
  text-transform: uppercase;
}
.banner .bgWrapper .content .subtitle,
.banner .bgWrapper .content .desc {
  margin-top: 2.6vh !important;
  font-size: 2vh!important;
  line-height: 2vh!important;
  height: auto!important;
}
.banner .bgWrapper .imgWrapper img {
  height: 35vh !important;
}
#reat-content {
  font-family: PingFang SC;
}
.logoMobile {
  width: 100%;
  height: 100%;
  position: absolute;
}
.logoBanner-mobile {
  background: #5b77ee;
  overflow: hidden;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 3.7vw;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.bodyMob {
  width: 100%;
  overflow-x: hidden;
  background-color: rgba(1, 57, 205, 0.88);
}
.bodyMob .btn-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vw;
  width: 100%;
  z-index: 100;
  background-color: #5b77ee;
}
.headerWrapper-mobile .logo {
  width: 34.6vw;
  height: 7.2vw;
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li span {
  color: #2972FB;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li::selection {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item-selected {
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: #333333;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 13vw;
  height: 90.5vh;
  overflow-x: hidden;
}
.banner-mobile .bgWrapper {
  background-size: 100% ;
  position: relative;
  margin: 0 auto;
  text-align: left;
  height: 100%;
}
.banner-mobile .bgWrapper .content {
  position: relative;
  z-index: 1;
  color: #fff;
  height: 100%;
  padding-top: 5vw;
}
.banner-mobile .bgWrapper .content .title {
  position: relative;
  font-family: PingFang SC;
  padding-top: 5vw;
  width: 82.5vw;
  height: 12.6vw;
  font-size: 4.8vw;
  line-height: 6vw;
  font-weight: 600;
  margin-left: 7vw;
}
.banner-mobile .bgWrapper .content .subtitle {
  margin-left: 7vw;
  width: 80vw;
  position: relative;
  padding-top: 5.6vw;
  font-size: 5vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.5vw;
  margin-bottom: 2.9vw;
}
.banner-mobile .bgWrapper .content .desc {
  width: 80vw;
  margin-left: 7vw;
  font-size: 4.8vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.5vw;
  margin-bottom: 13.6vw;
  margin-top: 5vw;
}
.banner-mobile .bgWrapper .content .word-download {
  margin-top: 55vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-mobile .bgWrapper .content .banner-btn {
  position: relative;
  text-align: center;
  margin: 0 auto;
  height: 12vw;
  border: 0;
  opacity: 1;
  border-radius: 1.2vw;
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  margin-bottom: 12vw;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn a {
  text-decoration: initial;
  color: #FFFFFF;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-img {
  width: 3.7vw;
  height: 3.7vw;
  margin-right: 0.3vw;
}
.banner-mobile .bgWrapper .imgWrapper {
  position: relative;
  z-index: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
.banner-mobile .bgWrapper .imgWrapper img {
  width: 100%;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
.block1-mobile {
  overflow: initial;
  position: relative;
  overflow: hidden;
}
.block1-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding-left: 7.1vw;
  padding-right: 7.1vw;
}
.block1-mobile .topTip h2 {
  height: 2.3vw;
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4vw;
  color: #1E7C51;
}
.block1-mobile .topTip img {
  width: 40.5vw;
  height: 4.3vw;
}
.block1-mobile .topTip div {
  padding-top: 1.8vw;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 4vw;
  color: #fff;
}
.block1-mobile .page-main-content {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 0 4.5vw;
  padding-top: 4.6vw;
  padding-bottom: 9.3vw;
  margin-top: -1px;
}
.block1-mobile .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block1-mobile .item-feature .cardInfo {
  position: relative;
  width: 50vw;
  height: 37vw;
  background: #ffffff;
  border-radius: 2.6vw;
  color: #333333;
}
.block1-mobile .item-feature .cardInfo .cardTitle {
  width: 24vw;
  height: 4.6vw;
  font-size: 3.1vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 4.6vw;
  opacity: 1;
  color: #fff;
}
.block1-mobile .item-feature .cardInfo .cardDetail {
  width: 49.2vw;
  font-size: 1.2vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2.1vw;
  opacity: 1;
  margin-bottom: 5vw;
}
.block1-mobile .item-feature .card0 {
  text-align: left;
  padding: 3.3vw 0 0 4.6vw;
}
.block1-mobile .item-feature .card0 .cardTitle {
  font-size: 3vw;
}
.block1-mobile .item-feature .card0 .cardDetail {
  width: 36.8vw;
  line-height: 4.3vw;
  font-size: 2vw;
}
.block1-mobile .item-feature .card1 {
  text-align: right;
}
.block1-mobile .item-feature .card1 .cardTitle {
  margin-left: -6vw;
  font-size: 3vw;
  line-height: 4.6vw;
  width: 49.2vw;
  margin-top: 5vw;
}
.block1-mobile .item-feature .card1 .cardDetail {
  width: 39vw;
  font-size: 2vw;
  line-height: 4.3vw;
  margin-left: 4vw;
}
.block1-mobile .item0 {
  margin-top: 4.5vw;
}
.block1-mobile .item0 .cardIcon {
  width: 28.7vw;
  margin-right: 7vw;
  position: absolute;
  left: 6vw;
  bottom: -12vw;
  opacity: 0.3;
}
.block1-mobile .item0 .cardInfo .cardDetail {
  margin-bottom: 3vw;
}
.block1-mobile .item1 {
  margin-top: -7vw;
  margin-left: 43vw;
}
.block1-mobile .item1 .cardIcon {
  width: 29.7vw;
  margin: -24vw 3vw 1vw 0;
  position: absolute;
  left: 12vw;
  top: 10vw;
  opacity: 0.3;
}
.block1-mobile .item2 {
  margin-top: -7vw;
}
.block1-mobile .item2 .cardIcon {
  margin-right: 7vw;
  width: 23.7vw;
  position: absolute;
  left: 6vw;
  bottom: -16vw;
  opacity: 0.3;
}
.block1-mobile .item2 .cardInfo .cardDetail {
  line-height: 4.6vw;
  margin-bottom: 6vw;
  margin-top: 1vw;
}
.block1-mobile .item3 {
  margin-top: -7vw;
  margin-left: 43vw;
}
.block1-mobile .item3 .cardIcon {
  width: 30.3vw;
  margin: -19vw 3vw 3vw 0;
  position: absolute;
  left: 11vw;
  top: 9vw;
  opacity: 0.3;
}
.block1-mobile .item3 .cardInfo .cardTitle {
  margin-top: 9vw;
}
.block3-mobile {
  overflow: initial;
  padding: 0 3.7vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding-bottom: 4.2vw;
  margin-top: -1px;
}
.block3-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block3-mobile .topTip h2 {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 4.5vw;
  color: #fff;
}
.block3-mobile .topTip div {
  margin-top: 1.8vw;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 4vw;
  color: #1E7C51;
  opacity: 1;
  margin-bottom: 4.6vw;
}
.block3-mobile .one-card {
  background: #ffffff;
  opacity: 1;
  border-radius: 4.2vw;
  display: flex;
  align-items: center;
  margin-top: 2.8vw;
  padding: 2.1vw 3.5vw;
}
.block3-mobile .one-card img {
  width: 20vw;
}
.block3-mobile .one-card .cardImg {
  width: 20vw;
}
.block3-mobile .one-card .common-card {
  margin-left: 3.7vw;
  padding-top: 2vw;
}
.block3-mobile .one-card .common-card .title {
  font-size: 3.3vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  margin-bottom: 1.5vw;
}
.block3-mobile .one-card .common-card .detail {
  margin-top: 0.8vw;
  font-size: 3vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.3vw;
  color: #333333;
  padding-right: 1vw;
}
.block3-mobile .one-card .common-card .detail p {
  margin-bottom: 0;
}
.footer-mobile {
  overflow: hidden;
  background: #5b77ee;
  padding: 0 4.5vw;
  padding-top: 0;
  margin-top: -1px;
}
.footer-mobile .one-center {
  background-color: #acbaf6;
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
  text-align: center;
  padding: 3vw 0;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 5.3vw;
  color: #fff;
  opacity: 1;
}
.footer-mobile .one-center .infos {
  height: 6.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  width: 48vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #fff;
  margin-top: 2.6vw;
}
.footer-mobile .one-center .infos a {
  color: #fff;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer-mobile .one-center-end {
  background-color: #acbaf6;
  margin-top: 10.1vw;
  opacity: 1;
  text-align: left;
  border-radius: 2.8vw 2.8vw 0 0;
  padding: 4.5vw 9vw;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4vw;
  color: #fff;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.6vw;
}
.footer-mobile .one-center-end .infos div {
  height: 4vw;
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 2.6vw;
  margin-right: 1.8vw;
  margin-top: 1vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #064852;
  color: #fff;
}
.ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}

.contentWrapper {
  background: #f8f8f8;
  padding: 20px;
  width: 800px;
  margin: auto;
}
.contentWrapper h1,
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #666;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: #f8f8f8;
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h1,
  .contentWrapper h2 {
    font-size: 22px;
    text-align: center;
    color: #333;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
  }
}

input {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
textarea {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: #ff7f7f;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background: #efefef;
  overflow: hidden;
}
.errorHeader {
  color: #fb4402;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #fb4402;
  text-align: center;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.loadingContent .errorMsg {
  color: #fff;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  margin: 0 3vw;
  background: #FEBC42;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper-mob {
  position: relative;
  min-height: 70vh;
  height: 100vh;
  display: block;
  background-color: #F5F9FF;
}
.deleteWrapper-mob .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 12vw;
  line-height: 12vw;
  z-index: 100;
  background-color: #344488;
}
.deleteWrapper-mob .tabNav .title {
  padding-left: 3.5vw;
  text-align: left;
  font-family: Roboto;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0;
  font-size: 3.9vw;
}
.deleteWrapper-mob .content {
  position: fixed;
  top: 12vw;
  left: 0;
  z-index: 1;
  right: 0;
  padding: 0 3.5vw;
  border-radius: 0 0 2.8vw 2.8vw;
}
.deleteWrapper-mob .tip {
  position: relative;
  padding-top: 1vw;
  margin: 0 0;
  text-align: left;
  font-size: 3vw;
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  letter-spacing: 0px;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 38px;
  color: #333333;
  text-align: left;
  padding: 0 35px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  padding: 0;
  padding-top: 3.5vw;
}
.deleteWrapper-mob .fbtInfo .fbtTitle {
  margin-top: 4.6vw;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  margin-left: 0;
  margin-bottom: 2.2vw;
  font-size: 3vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtInfo .fbtTitle:first-child {
  margin-top: 0;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 42px;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #333333;
}
.deleteWrapper-mob .fbtItem {
  background: #ffffff;
  border-radius: 1.2vw;
}
.deleteWrapper-mob .fbtPhone {
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  margin-left: 4.6vw;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #333;
  color: #333;
  letter-spacing: 0px;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
  margin-left: 2.8vw;
  border: 0;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  border: 0;
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  border: 0;
  color: #cccccc;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  padding-left: 4.6vw;
  margin-left: 0;
}
.deleteWrapper-mob .showTips {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.2vw;
  padding-top: 0.8vw;
}
.deleteWrapper-mob .showTips span {
  font-size: 2.4vw;
  color: #ff7f7f;
}
.deleteWrapper-mob .fbtContentError {
  background-color: #ff7f7f;
}
.deleteWrapper-mob .fbtContentError .fpInfo .fpInput {
  color: #FFFFFF;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #cccccc;
  font-weight: 400;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #cccccc;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect {
  padding: 0 4.6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
  opacity: 1;
  padding-top: 2.2vw;
  padding-bottom: 2.2vw;
  font-size: 3.3vw;
  margin-bottom: 4.6vw;
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #fff;
  width: 100%;
  background: initial;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #cccccc;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #cccccc;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect .itemImg {
  width: 4.6vw;
  height: 4.6vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 2.2vw 4.6vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  opacity: 1;
  box-sizing: border-box;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #333;
  caret-color: #cccccc;
  width: 100%;
  height: 12vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #cccccc;
  font-weight: 400;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #cccccc;
  font-weight: 400;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #cccccc;
  font-weight: 400;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #cccccc;
  font-weight: 400;
}
.deleteWrapper-mob .fbtContentOn {
  opacity: 1;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .fbtCountry .fpInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.4vw;
  padding-left: 4.6vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtCountry .fpInfo .countrylogo {
  height: 5vw;
}
.deleteWrapper-mob .fbtCountry .fpInfo .iSelect {
  position: relative;
  width: 70%;
  height: 100%;
  cursor: pointer;
  border: 0;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #333;
  width: 100%;
  background: initial;
}
.deleteWrapper-mob .fbtCountry .fpInfo .countryName {
  font-family: Roboto;
  font-weight: bold;
  width: 80vw;
  font-size: 3.3vw;
  height: 5vw;
  text-align: left;
  line-height: 5vw;
  color: #333333;
  padding-left: 0.5vw;
  padding-right: 1vw;
}
.deleteWrapper-mob .fbtCountry .fpInfo .countryMore {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10vw;
  border-radius: 0 1.2vw 1.2vw 0;
  background-color: #5B77EE;
}
.deleteWrapper-mob .fbtCountry .fpInfo .countryMore .moreImg {
  width: 3vw;
}
.deleteWrapper-mob .agreeWrapper {
  position: relative;
  margin: 0 2.2vw;
  margin-top: 5.9vw;
  margin-bottom: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #5B77EE;
  border-radius: 50%;
  width: 3.6vw;
  height: 3.6vw;
  color: #fff;
  font-size: 2.2vw;
  background-color: #ffffff;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  background-color: #5B77EE;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  top: 0.3vw;
  height: 2.7vw;
  width: 3.6vw;
  height: 3.6vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  max-width: 90%;
  padding-left: 1vw;
  color: #333333;
  letter-spacing: 0px;
  font-size: 3vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 4.5vw;
  text-align: left;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #acbaf6;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #acbaf6;
  font-weight: bold;
  padding: 0 0.8vw;
}
.deleteWrapper-mob .fbtInfoCl {
  padding-top: 0;
  padding-bottom: 4.9vh;
}
.deleteWrapper-mob .fbtInfoCl .fbtPhone {
  margin-top: 3.2vw !important;
}
.deleteWrapper-mob .fbtInfoCl .fbtContent {
  margin-top: 3.2vw !important;
}
.deleteWrapper-mob .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5vh;
  height: 9.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #febc42;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 0px;
  margin: 0 8.3vw;
  border-radius: 4.6vw;
  z-index: 10;
  opacity: 0.3;
}
.deleteWrapper-mob .fbtActionOn {
  opacity: 1;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 80vh;
  margin: 0 auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  z-index: 100;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #333333;
}
.deleteWrapperColor-mob .tabNav {
  height: 100vh;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #333333;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
.edit-mob .content {
  position: absolute;
  top: 13vw;
  left: 0;
  right: 0;
  overflow-y: scroll;
  margin-bottom: 40vh;
  padding-bottom: 5vh;
  border-radius: 21px;
  height: 80vh !important;
  z-index: 0;
}
.edit-mob .content .tip {
  display: none;
}
.edit-mob .content .fbtInfo {
  padding-top: 2vw;
  padding-bottom: 2vw;
  display: block;
}
.selectModal-mob .modalContent {
  border-radius: 2.8vw 2.8vw 0 0;
  margin: 0 0;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 0 4.6vw;
  border-bottom: 1px dashed #333;
}
.errorMsg-mob {
  font-size: 4.6vw !important;
  padding: 3.6vw !important;
}
.loader-mob {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.btn-mob {
  margin: 0 17vw;
  border-radius: 2.2vw;
  font-size: 4.6vw;
  padding: 1vw 4vw;
  margin-bottom: 3.5vw;
  color: #ffffff;
}

input {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
textarea {
  border: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0 !important;
  padding: 1vh 6vw !important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: #ff7f7f;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  line-height: initial !important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  bottom: 35vh !important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center !important;
  margin: 0 auto !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh !important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  border-radius: 1.5vh;
  margin: 0 15vw;
  background: #efefef;
  overflow: hidden;
}
.errorHeader {
  color: #fb4402;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2vw 0;
}
.errorMsg {
  color: #fb4402;
  text-align: center;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.loadingContent .errorMsg {
  color: #fff;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  margin: 0 3vw;
  background: #FEBC42;
  color: #fff;
  cursor: pointer;
}
.deleteWrapper {
  padding: 2.1vh;
  padding-top: 8vh;
  position: relative;
  min-height: 100vh;
  background-color: #F5F9FF;
}
.deleteWrapper .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 9vh;
  z-index: 1;
  background-color: #344488;
}
.deleteWrapper .tabNav .title {
  padding-left: 5vh;
  line-height: 9vh;
  font-size: 2.6vh;
  font-family: Roboto;
  font-weight: bold;
  text-align: left;
  color: #fff;
  letter-spacing: 0px;
}
.deleteWrapper .content {
  position: fixed;
  z-index: 1;
  top: 10vh;
  height: 90vh;
  left: 0;
  right: 0;
  margin: 0 5vh;
}
.deleteWrapper .tip {
  position: relative;
  padding-top: 2.2vh;
  margin: 0;
  text-align: left;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  line-height: 2vh;
  color: #333333;
  letter-spacing: 0px;
  opacity: 1;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #333333;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
  overflow: hidden;
}
.deleteWrapper .fbtInfo .fbtTitle {
  margin-top: 2.2vh;
  font-family: Roboto;
  color: #333333;
  letter-spacing: 0px;
  margin-left: 0;
  margin-bottom: 1.2vw;
  font-size: 2vh;
  line-height: 2.2vh;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #333333;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  padding-left: 4.5vh;
}
.deleteWrapper .fbtCol .need {
  color: #333333;
}
.deleteWrapper .fbtItem {
  background: #ffffff;
  border-radius: 1.2vw;
}
.deleteWrapper .fbtPhone {
  padding: 1vh 0;
  opacity: 1;
  width: 100%;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  margin-left: 2.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  caret-color: #333;
  color: #333;
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  border: 0;
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  border: 0;
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  padding-left: 2.2vw;
  margin-left: 0;
}
.deleteWrapper .showTips {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.2vw;
  padding-top: 0.8vw;
}
.deleteWrapper .showTips span {
  font-size: 2.4vh;
  color: #ff7f7f;
}
.deleteWrapper .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtContentError {
  background-color: #ff7f7f;
}
.deleteWrapper .fbtContentError .fpInfo .fpInput {
  color: #FFFFFF;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #cccccc;
}
.deleteWrapper .fbtCountry .fpInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.2vh;
  padding-left: 2.2vw;
  cursor: pointer;
}
.deleteWrapper .fbtCountry .fpInfo .countrylogo {
  height: 3.2vh;
}
.deleteWrapper .fbtCountry .fpInfo .iSelect {
  position: relative;
  width: 70%;
  height: 100%;
  cursor: pointer;
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #333;
  width: 100%;
  background: initial;
}
.deleteWrapper .fbtCountry .fpInfo .countryName {
  font-family: Roboto;
  font-weight: bold;
  width: 80vw;
  font-size: 2vh;
  height: 3.2vh;
  text-align: left;
  line-height: 3.2vh;
  color: #333333;
  padding-left: 0.5vw;
  padding-right: 1vw;
}
.deleteWrapper .fbtCountry .fpInfo .countryMore {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 5vw;
  border-radius: 0 1.2vw 1.2vw 0;
  background-color: #5B77EE;
}
.deleteWrapper .fbtCountry .fpInfo .countryMore .moreImg {
  width: 2vw;
}
.deleteWrapper .itemSelect {
  padding: 0 2.2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(136, 138, 149, 0);
  opacity: 1;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
  margin-bottom: 2.2vh;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #fff;
  width: 100%;
  background: initial;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect .itemImg {
  width: 2.2vh;
  height: 2.2vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 1vh 2.2vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  box-sizing: border-box;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #333;
  caret-color: #cccccc;
  padding-top: 0.1vh;
  width: 100%;
  height: 4vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #cccccc;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #cccccc;
}
.deleteWrapper .itemSelect::placeholder {
  color: #cccccc;
}
.deleteWrapper .fbtContentOn {
  opacity: 1;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin: 0 2.1vh;
  margin-top: 2.2vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  border: 1px solid #5B77EE;
  width: 2.4vh;
  height: 2.4vh;
  margin-left: 8px;
  margin-top: 2px;
  border-radius: 50%;
  margin-right: 2.1vh;
  color: #fff;
  font-size: 1.6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.deleteWrapper .agreeWrapper .hasCheck {
  background-color: #5B77EE;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4vh;
  height: 2.4vh;
}
.deleteWrapper .agreeWrapper .laText {
  display: flex;
  margin-left: 1.1vh;
  color: #333333;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #acbaf6;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #acbaf6;
  font-weight: bold;
  padding: 0 1.1vw;
}
.deleteWrapper .fbtInfoCl {
  padding-top: 0;
}
.deleteWrapper .fbtInfoCl .fbtPhone {
  margin-top: 2.1vh !important;
}
.deleteWrapper .fbtInfoCl .fbtContent {
  margin-top: 2.1vh !important;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #febc42;
  bottom: 2vh;
  height: 6.8vh;
  font-size: 3.3vh;
  color: #FFFFFF;
  letter-spacing: 0px;
  margin: 0 10.4vh;
  border-radius: 4.2vh;
  cursor: pointer;
  opacity: 0.3;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 80vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  color: #333333;
  padding-top: 0;
}
.deleteWrapperColor .tabNav {
  z-index: 0;
  height: 100vh;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #333333;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh !important;
}
.list .am-radio {
  height: 4vh !important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw !important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #b92d00;
}
.selectModal {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: flex-end;
}
.modalContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 0;
  background: #fff;
  margin: 0;
}
.modalContent .listHead {
  background: #eee;
  font-weight: bold;
  color: #666;
  border-bottom: 1px solid #333333;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 8vh;
  padding-left: 3.5vw;
  line-height: 8vh;
  font-size: 2.2vh;
  font-family: Roboto;
}
.modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.modalContent .list {
  position: relative;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
}
.modalContent .list .listItem {
  position: relative;
  margin: 0 69px;
  text-align: center;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  padding: 1.5vh 0;
  border-bottom: 1px dashed #333;
}
.modalContent .list .listItem:last-child {
  border-bottom: 0;
}
.modalContent .list .listItemOn {
  color: #5B77EE;
  background-color: rgba(113, 162, 255, 0);
  font-weight: bold;
}
.modalContent .list .listItemOn .listItem {
  position: relative;
  color: #5B77EE;
}
.modalContent .list::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 40px;
}
.modalContent .list::-webkit-scrollbar-button {
  color: #333333;
}
.modalContent .list::-webkit-scrollbar-thumb {
  background-position: center;
  border-radius: 10px;
  height: 40px;
  background-color: #eee;
}
.loader {
  border: 0.5vh solid #fff;
  border-top: 0.5vh solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  margin-top: 5vh;
  -webkit-animation: spin 0.5s linear infinite;
          animation: spin 0.5s linear infinite;
}
.errorMsg {
  font-size: 2.2vh;
  padding: 3.3vh;
}
.btn {
  margin: 0 8vh;
  border-radius: 1.2vh;
  font-size: 2.2vh;
  padding: 1vh 4vh;
  margin-bottom: 3.5vh;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-content: center;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}
#root {
  height: 100%;
  width: 100%;
}
.hairlines {
  overflow: hidden;
}
.prevention-container {
  background: linear-gradient(180deg, #fdfdfd 0%, #EB536A 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  top: 0;
}
.prevention-container .warnBg {
  width: 100vw;
  margin-bottom: -12vw;
}
.prevention-container .prevention-content {
  position: relative;
  background-color: #FFFFFF;
  padding: 3vw;
  height: 100%;
  margin: 0 5.6vw;
}
.prevention-container .prevention-content h2 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 5.6vw;
  color: #FEBC42;
  text-align: center;
  margin: 0 0 6vw 0;
}
.prevention-container .prevention-content .desc {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.9vw;
  color: #333333;
  letter-spacing: 0px;
  padding: 5vw 3vw 0vw 3vw;
  margin-bottom: 2.8vw;
}
.prevention-container .prevention-content .service-selections {
  width: 60vw;
  height: 8vw;
  background: #febc42;
  border-radius: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4vw auto;
}
.prevention-container .prevention-content .service-selections .phoneIcon {
  width: 3.5vw;
  height: auto;
  margin-right: 1vw;
}
.prevention-container .prevention-content .service-selections img {
  width: 3.5vw;
  height: 2.5vw;
  margin-right: 1vw;
}
.prevention-container .prevention-content .service-selections span {
  font-family: Roboto-Bold;
  font-size: 4.4vw;
  color: #FFFFFF;
}
.prevention-container .prevention-content .service-selections .copy-tooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4vw;
  border-radius: 4px;
  font-size: 5vw;
  z-index: 1000;
  transition: opacity 0.3s ease;
}
.prevention-container .prevention-content::before,
.prevention-container .prevention-content::after {
  content: "";
  position: absolute;
  width: 5vw;
  /* 半圆的宽度 */
  height: 5vw;
  /* 半圆的高度 */
  background-color: transparent;
  /* 透明背景 */
  border-radius: 50%;
  /* 圆形 */
  top: 12vw;
  /* 设置半圆出现在顶部 */
}
.prevention-container .prevention-content::before {
  left: -2.5vw;
  /* 向左偏移一半宽度 */
}
.prevention-container .prevention-content::after {
  right: -2.5vw;
  /* 向右偏移一半宽度 */
}
.prevention-container .guideline-container {
  position: relative;
  background-color: #FFFFFF;
  padding: 5vw;
  margin: 5vw 5.6vw;
  margin-bottom: 10vw;
}
.prevention-container .guideline-container h2 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 5.6vw;
  color: #5B77EE;
  text-align: center;
  margin-top: 0;
}
.prevention-container .guideline-container .payment-container {
  position: relative;
  margin-top: 5vw;
  display: flex;
  grid-column-gap: 10vw;
  -webkit-column-gap: 10vw;
          column-gap: 10vw;
  justify-content: center;
  align-items: center;
}
.prevention-container .guideline-container .payment-container .payment-unit {
  display: flex;
  flex-direction: column;
}
.prevention-container .guideline-container .payment-container .payment-unit img {
  cursor: pointer;
  width: 20vw;
  height: 20vw;
}
.prevention-container .guideline-container .payment-container .payment-unit span {
  margin-top: 1vw;
  font-family: Roboto;
  font-weight: bold;
  font-size: 3vw;
  color: #333333;
  text-align: center;
}
.prevention-container .guideline-container .other-title {
  font-family: Roboto-Regular;
  font-size: 5vw;
  color: #ACBAF6;
  text-align: center;
  margin: 2vw auto 4vw auto;
  display: block;
}
.prevention-container .guideline-container .other-content {
  position: relative;
  background: rgba(245, 249, 255);
  width: 95%;
  height: 15vw;
  border-radius: 40vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1.5vw;
}
.prevention-container .guideline-container .other-content img {
  width: 64vw;
  height: 8.3vw;
}
.prevention-container .footer {
  opacity: 0;
  margin: 10vw;
}
.prevention-container .guideModal-bg {
  width: 100vw;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.prevention-container .guideModal-bg .guideModal-container {
  padding: 5vw;
  margin: 8vw 4vw;
  background-color: #FFFFFF;
  position: relative;
  border-radius: 5vw;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 90%;
}
.prevention-container .guideModal-bg .guideModal-container .title {
  font-family: Roboto;
  font-weight: bold;
  font-size: 4.4vw;
  color: #344488;
}
.prevention-container .guideModal-bg .guideModal-container .title span {
  width: 90vw;
  display: block;
  margin: 0 auto;
  text-align: center;
}
.prevention-container .guideModal-bg .guideModal-container .title .cancelImg {
  position: absolute;
  right: 6vw;
  top: 5vw;
  width: 2.8vw;
  height: 2.8vw;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-name {
  display: flex;
  justify-content: space-between;
  font-family: Roboto;
  font-weight: bold;
  color: #F6BA4A;
  font-size: 4.4vw;
  margin: 2vw 0;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-name a {
  text-decoration: underline;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-content {
  margin: 1vw 0;
  display: block;
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #333333;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-way {
  margin: 1vw 0;
  display: block;
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #ACBAF6;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-notice {
  margin-top: 3vw;
  line-height: 0.5;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-notice span {
  font-family: Roboto;
  font-weight: 400;
  font-size: 3.3vw;
  color: #5B77EE;
  margin-bottom: 0;
}
.prevention-container .guideModal-bg .guideModal-container .selections .guide-img {
  width: 81.5vw;
  height: auto;
}
.prevention-container .guideModal-bg .guideModal-container .selections .other-img {
  display: block;
  margin: 2vw auto;
  width: 64vw;
  height: 8.3vw;
}
.prevention-container .guideModal-bg .guideModal-container .selections .line-gap {
  border: 1px solid #DAE9FF;
  margin: 1vw;
  opacity: 0.5;
}

.phone-lost-container {
  background: rgba(29, 168, 241);
  background-image: url(../../static/media/bgMain.e5047adb.png);
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 4vw;
}
.phone-lost-container .title {
  font-family: Gabana;
  font-weight: 900;
  font-size: 11.1vw;
  color: #1CADD0;
  margin: 5vw auto 2vw auto;
  text-align: center;
}
.phone-lost-container .desc-container {
  background: linear-gradient(180deg, #5BD9F8 0%, #1DA8F1 100%);
  border-radius: 4vw 0px 4vw 0px;
  margin: 0 9.3vw;
}
.phone-lost-container .desc-container span {
  display: block;
  margin: 0 auto;
  font-family: Gabana;
  font-weight: 900;
  font-size: 3.3vw;
  color: #FFFFFF;
  text-align: center;
  padding: 2.5vw;
}
.phone-lost-container .handImg {
  width: 54.7vw;
  display: block;
  margin: 4vw auto -18vw auto;
  z-index: 1;
  position: relative;
}
.phone-lost-container .step-container {
  background: rgba(255, 255, 255);
  border-radius: 2.8vw;
  padding: 0vw 3vw 0 4vw;
  margin: 2vw 4.6vw;
  z-index: 20;
  position: relative;
}
.phone-lost-container .step-container .title-selections1 {
  display: flex;
  flex-direction: row;
}
.phone-lost-container .step-container .title-selections1 .step {
  padding: 3.5vw 4vw 1vw 4vw;
  background: rgba(254, 188, 66);
  border-radius: 0px 0px 2.8vw 2.8vw;
  margin-bottom: 0;
}
.phone-lost-container .step-container .title-selections1 .step img {
  height: 6.9vw;
  width: 2.6vw;
  margin: 0 auto;
}
.phone-lost-container .step-container .title-selections1 .desc {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #333333;
  margin-left: 2.8vw;
  padding-top: 2.8vw;
}
.phone-lost-container .step-container .title-selections2 {
  display: flex;
  flex-direction: row;
}
.phone-lost-container .step-container .title-selections2 .step {
  padding: 3.5vw 3vw 2vw 3vw;
  background: rgba(254, 188, 66);
  border-radius: 0px 0px 2.8vw 2.8vw;
  margin-bottom: 0;
}
.phone-lost-container .step-container .title-selections2 .step img {
  height: 6.8vw;
  width: 5.4vw;
  margin: 0 auto;
}
.phone-lost-container .step-container .title-selections2 .desc {
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #333333;
  margin-left: 2.8vw;
  padding-top: 1vw;
  max-width: 80vw;
  word-break: break-all;
}
.phone-lost-container .step-container .title-selections2 .desc span {
  margin-left: 1vw;
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: blue;
}
.phone-lost-container .step-container .title-selections2 .desc .copy-icon {
  width: 2.6vw;
}
.phone-lost-container .step-container .guide-img {
  padding: 2vw 0;
}
.phone-lost-container .step-container .guide-img img {
  width: 70.1vw;
  display: block;
  margin: 0 auto 2vw auto;
}
.phone-lost-container .step-container .step-desc {
  margin-top: 2vw;
  font-family: Roboto;
  font-weight: bold;
  font-size: 3.3vw;
  color: #344488;
}
.phone-lost-container .step-container .step-desc .small-text {
  font-family: Roboto;
  font-weight: 400;
  font-size: 2.6vw;
  color: #999999;
}
.phone-lost-container .step-container .guide-img-selection {
  padding: 2vw 0;
}
.phone-lost-container .step-container .guide-img-selection p {
  margin: 0;
  font-family: Roboto;
  font-weight: 400;
  font-size: 3.3vw;
  color: #333333;
}
.phone-lost-container .step-container .guide-img-selection img {
  display: block;
  width: 78.7vw;
  margin: 2vw auto;
}
.phone-lost-container .copy-tooltip {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3vw;
  border-radius: 4px;
  font-size: 5vw;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.guidanceWrapper {
  background: #fafafa;
  padding: 20px 35px;
  text-align: left;
  padding-bottom: 111px;
}
.guidanceWrapper p {
  display: block;
  font-size: 25px!important;
  line-height: 30px;
  margin-bottom: 15px;
  color: #333333;
  padding: 0;
}
.guidanceWrapper p strong {
  font-size: 32px!important;
}
.guidanceWrapper p strong span {
  font-size: 32px!important;
}
.guidanceWrapper img {
  max-width: 100%!important;
  width: 100%!important;
  height: auto;
  margin: 10px auto;
}
.guidanceWrapper .guidanceContent {
  margin: 30px 0;
  padding: 8px 21px 42px 21px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 14px;
}
.guidanceWrapper .guidanceContent .guidanceTitle {
  margin: 40px 0;
  padding: 0;
  text-align: left;
  font-size: 38px;
  font-family: Roboto;
  font-weight: 500;
  color: #333333;
}
.guidanceWrapper .guidanceContent .secendTitle {
  margin: 50px 0 0 0;
  padding: 0;
  text-align: left;
  font-size: 30px;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
}
.guidanceWrapper .guidanceContent .stepTitle {
  padding: 0;
  font-size: 48px;
  font-family: Roboto;
  font-weight: bold;
  color: #24368C;
  margin: 21px 0;
  text-align: left;
}
.guidanceWrapper .guidanceContent .stepTitle span {
  font-size: 50px;
  font-family: Roboto;
  font-weight: bold;
}
.guidanceWrapper .guidanceContent .stepWord {
  padding: 0;
  margin: 16px 0;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: #333333;
  text-align: left;
  opacity: 1;
}
.guidanceWrapper .guidanceContent .stepMargintop {
  margin-top: 42px;
}
.guidanceWrapper .guidanceContent .firstLoan {
  padding: 0;
  margin: 16px 0;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: #333333;
  font-weight: bold;
  text-align: left;
  opacity: 1;
}
.guidanceWrapper .guidanceContent .fstepWord {
  padding: 0;
  margin: 16px 0;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: #333333;
  text-align: left;
  opacity: 1;
}
.guidanceWrapper .guidanceContent img {
  margin-top: 10px;
  width: 100%;
}
.guidanceWrapper .guidanceContent .strong {
  font-weight: bold;
}
.guidanceWrapper .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 21px 42px;
}
.guidanceWrapper .footer .readBtn {
  width: 100%;
  height: 90px;
  background: #F6BA4A;
  border-radius: 49px;
  font-size: 42px;
  font-family: Roboto;
  font-weight: bold;
  line-height: 90px;
  color: #FFFFFF;
  text-align: center;
}

